<template>
    <div id="app">
        <div class="container-fluid w-100 h-100 d-flex flex-column">
            <div class="d-inline-block w-100 container__item">
                <Menu @historyScroll="scrollToHistory" @contactScroll="scrollToContact" @portfolioScroll="scrollToPortfolio"></Menu>
            </div>
            <div class="container">
                <div class="d-inline-block w-100 h-100 flex-column container__item">
                    <intro-text name="Peter David McHugh" job-title="Frontend Web Developer"></intro-text>
                </div>
            </div>
            <div class="d-inline-block container w-100 container__item">
                <Intro-main></Intro-main>
            </div>
        </div>
        <div class="container d-flex flex-row w-100 pt-2 pt-md-5 pb-2 pb-md-5" id="history">
            <div class="flex-column w-100">
                <history></history>
            </div>
        </div>
        <div class="container d-flex flex-row pt-0 mb-0 pt-md-5 pb-md-5 w-100" id="portfolio">
            <div class="flex-column w-100">
                <portfolio></portfolio>
            </div>
        </div>
        <div class="container fullscreen d-flex flex-row w-100 contact-section" id="contact">
            <div class="flex-column w-100">
                <contact></contact>
            </div>
        </div>
        <div class="footer m-0 pb-3">
            <p class="mb-0">&copy; Peter David McHugh {{ this.year }}</p>
        </div>
    </div>
</template>

<script>
    import IntroText from "@/components/IntroText";
    import IntroMain from './components/IntroMain';
    import Menu from "@/components/Menu";
    import History from "@/components/history";
    import Contact from "@/components/contactme";
    import Portfolio from "@/components/Portfolio";
    import $ from "jquery"

    export default {
        name: 'App',
        data() {
            return {
                history: false,
                contact: true,
                year: new Date().getFullYear()
            }
        },
        components: {
            IntroMain, IntroText, Menu, History, Contact, Portfolio
        },
        methods: {
            scrollToHistory(value) {
                let position = $("#history").offset().top
                $('html, body').animate({
                    scrollTop: position
                }, 800)
            },
            scrollToContact(value) {
                let position = $("#contact").offset().top
                $('html, body').animate({
                    scrollTop: position
                }, 800)
            },
            scrollToPortfolio(value) {
                let position = $("#portfolio").offset().top
                $('html, body').animate({
                    scrollTop: position
                }, 800)
            },
            scrollToTop() {
                $('html, body').animate({
                    scrollTop: 0
                }, 800)
            }
        }
    }
</script>

<style lang="scss">
    @import './styles/_main.scss';
    @media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1420px !important;
    }

    }
    .footer{
        background: #131818;
    }
    .contact-section{
        background: #131818;
        &.container{
            background: #131818;
            min-width: 100%;
        }
    }
    .container{
        &-restrict{
            max-width: 80%;
            margin: 0 auto;
        }
        &__item{
            &:nth-of-type(1){
                height: 20%;
            }
            &:nth-of-type(2){
                height: 10%;
            }
            &:nth-of-type(3){
                height: 40%;
            }
         }
    }
    .primary{
        font-family: 'Raleway', sans-serif;
    }
    .secondary{
        font-family: 'Noto Sans', sans-serif;
    }
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        text-align: center;
        color: #2c3e50;
        height: 100%;
        background-color: #131818;
    }

    html, body {
        height: 100%;
        width: 100%;
        background-color: #131818;
        scroll-behavior: smooth;
    }

    .fullscreen {
        height: 100%;
        position: relative;
    }
</style>
