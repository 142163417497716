//noinspection Duplicates
<!--suppress ALL -->
<template>
    <div class="d-flex container-order flex-row flex-lg-row-reverse row mt-3 col col-12 pr-0 mr-0 mt-md-5 pb-3 pb-md-5">
        <div class="d-flex flex-column col mt-2 mb-4 mb-lg-0 pt-4 pt-lg-0 mt-lg-0 ml-5 col-12 col-lg-4">
            <div class="history-container">
                <div class="timeline">
                    <div class="d-flex flex-row" v-for="item in history.jobs" :key="history.item">
                        <a :href="`http://` + `${item.link}`" target="_blank">
                            <p class="timeline__year">{{ item.year }}</p>
                            <p class="timeline__company">{{ item.company }}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column col mb-4 mb-lg-0 pb-3 pb-lg-0 pr-0 mt-0">
            <div class="photo mt-0 mb-5 pt-lg-2 mt-lg-3 w-100">
                <figure class="figure">
                    <img src="@/assets/freelancepete.png" alt="Pete">
                    <figcaption class="figure-caption text-right pt-2">It's me! ft. Vue.js</figcaption>
                </figure>
            </div>
            <div class="timeline-container mt-0 mt-lg-4 flex-column">
                <p class="timeline__text">Hey! I'm Pete, here's a little bit about me...</p>
                <p class="timeline__text">Currently based in <strong>Manchester, England.</strong></p>
                <p class="timeline__text">I create &amp; develop a range of websites using the latest in technology and techniques within digital agencies &amp; companies such as <strong>CoachUSA</strong>, <strong>Mesosys</strong>, <strong>Mobysoft</strong>, <strong>QueryClick</strong>, <strong>The Realbuzz Group</strong>, <strong>Prodo Digital</strong>, <strong>Mobysoft</strong>, <strong>Discerning Digital</strong>, <strong>Origin Creative</strong>,  <strong>Mosquito Digital</strong>,  <strong>Bloom Agency</strong>, <strong>Stein IAS</strong>,  <strong>Line Communications</strong>, <strong>Outdoor Leisurewear</strong> and the <strong>NHS</strong>.</p>
                <p class="timeline__text">My skillsets: <strong>HTML5, CSS3 (SASS, LESS), JavaScript (ES6), Vue.js, React.js, jQuery, Bootstrap</strong></p>
                <p class="timeline__text">My tools of choice: <strong>GIT, Grunt, BEM Methodology, JetBrains (IntelliJ, PyCharm), Adobe Creative Suite (InDesign, Photoshop)</strong></p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "history",
        data () {
            return {
                history: {
                    'jobs': [
                    {
                        'company': 'Mesosys / CoachUSA',
                        'year': '2023',
                        'link': 'www.coachusa.com'
                    }, {
                        'company': 'Itchpet',
                        'year': '2022',
                        'link': 'www.itchpet.com'
                    }, {
                        'company': 'Mesosys / CoachUSA',
                        'year': '',
                        'link': 'www.coachusa.com'
                    }, {
                        'company': 'Mesosys',
                        'year': '2021',
                    }, {
                        'company': 'Mobysoft',
                        'year': '2021',
                        'link': 'www.mobysoft.com'
                    }, {
                        'company': 'Query Click',
                        'year': '2020',
                        'link': 'www.queryclick.com'
                    }, {
                        'company': 'Mobysoft',
                        'year': '2018',
                        'link': 'www.mobysoft.com'
                    }, {
                        'company': 'Mosquito Digital',
                        'year': '',
                        'link': 'www.mosquitodigital.co.uk'
                    }, {
                        'company': 'The Realbuzz Group',
                        'year': '2017',
                        'link': 'www.realbuzzgroup.com/realbuzz/'
                    }, {
                        'company': 'Prodo Digital',
                        'year': '2016',
                        'link': 'www.prodo.com/'
                    }, {
                        'company': 'Discerning Digital',
                        'year': '',
                        'link' : 'discerningdigital.co.uk'
                    }, {
                        'company': 'Origin Creative (MMP)',
                        'year': '',
                        'link':'www.theapsgroup.com/en-gb/'
                    }, {
                        'company': 'Mosquito Digital',
                        'year': '2015',
                        'link': 'www.mosquitodigital.co.uk'
                    }, {
                        'company': 'Bloom Agency',
                        'year': '',
                        'link': 'www.bloomagency.co.uk/'
                    }, {
                        'company': 'Stein IAS',
                        'year': '',
                        'link':'www.steinias.com/'
                    }, {
                        'company': 'NHS Pennine Acute Hospitals',
                        'year': '2014',
                        'link': 'www.pat.nhs.uk/'
                    }, {
                        'company': 'Line Communications (LEO)',
                        'year': '',
                        'link': 'leolearning.com/'
                    }, {
                        'company': 'Outdoor Leisurewear',
                        'year': '2013',
                        'link': 'outdoorleisurewear.co.uk/'
                    }
                    ]
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .container-order{
        div{
            &:nth-child(1){
                order: 1
            }
            &:nth-child(1){
                order: 1
            }
        }
    }
    .skills{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        img{
            width: auto;
            height: auto;
            max-height: 100px;
            max-width: 100px;
        }
    }
    .photo {
        width: 100%;
        height: 100%;
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        max-height: 241px;
        @media (min-width: 768px) {
            max-height: auto;
        } img{
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 241px;
            margin-top: 3em;
            @media all and (-ms-high-contrast:none) {
                margin-top: 3em;
            }
            @media (min-width: 768px) {
                margin-top: 0;
                max-height: 340px;
                min-height: 340px;
                max-width: 504px;
            }
        }
    }
    .history-container{
        position: relative;
    }
    .timeline{
        border-left: 2px solid #fff;
        display: inline-block;
        margin-left: 0.5em;
        padding-top: 1.5em;
        position: relative;
        > :first-child{
            .timeline__year{
                left: -86px;
                top: -28px;
                font-weight: bold;
            }
        }
        a{
            &:hover{
                text-decoration: none;
            }
        }
        &:before{
            content: '';
            display: block;
            position: absolute;
            border: 2px solid #fff;
            height: 18px;
            width: 18px;
            border-radius: 100px;
            left: -10px;
            top: -18px;
        }
        &:after{
            content: '';
            display: block;
            position: absolute;
            border: 2px solid #777;
            height: 18px;
            width: 18px;
            border-radius: 100px;
            left: -10px;
            bottom: -18px;
        }
        &-container{
            display: flex;
            @media all and (-ms-high-contrast:none) {
                display: inline-block;
            }
        }
        &__text{
            text-align: justify;
            @media (min-width: 768px) {
                text-align: left;
            }

        }
        &__title{
            text-align: left;
            border: none;
            font-weight: 600;
            position: relative;
            top: 3px;
            left: -14px;
        }
        &__company{
            border: 1px solid #fff;
            padding: 0.5em 0.75em;
            margin-left: 16px;
            position: relative;
            left: 0;
            background-color: none;
            transition: background-color 0.2s ease-in-out;
            @media (min-width: 768px) {
                left: 0;
                &:hover{
                    transition: background-color 0.2s ease-in-out;
                    background-color: #fff;
                    color: #333;
                }
            }
            &:before {
                content: " ";
                position: absolute;
                left: -17px;
                width: 17px;
                border-bottom: 1px solid #fff;
                top: calc(50% - 1px);
            }
        }
        &__year{
            position: absolute;
            left: -66px;
            padding: 1em;
            border-radius: 100px;
            padding: 0.5em 0.75em;
        }
    }
</style>
