<template>
    <div class="d-flex flex-column align-items-center align-items-md-start justify-content-end">
        <p class="name text-center text-md-left mb-0 primary">
            <span class="text">
                {{ name }}
                <span class="shadow text-center text-md-left mb-0 primary">{{ name }}</span>
            </span>
        </p>
        <p class="job secondary">{{ jobTitle }}</p>
    </div>
</template>

<script>
export default {
    name: 'IntroText',
    props: {
        name: String,
        jobTitle: String
    }
}
</script>

<style lang="scss" scoped>
    .container {
        height: 100%;
    }
    p {
        color: white;
        position: relative;
        &.name{
            font-size: 1.5em;
            letter-spacing: -2px;
            width: 100%;
            @media (min-width: 768px) {
                font-size: 2.5em;
            }
        }

        &.job{
            text-transform: uppercase;
            font-size: 1em;
            letter-spacing: 1px;
            @media (min-width: 768px) {
                font-size: 1.5em;
            }
        }
    }
    .shadow{
        display: none;
        position: absolute;
        top: 2px;
        left: 2px;
        z-index: -1;
        color: #6c757d;
        font-weight: 600;
        opacity: 0.65;
        font-size: 1em;
        @media (min-width: 768px) {
            display: block;
        }
    }
    .text{
        position: relative;
        z-index: 10;
        display: block;
    }
</style>