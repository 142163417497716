<template>
    <div class="d-flex flex-row justify-content-center justify-content-md-end align-items-start w-100">
        <div class="menu__item">
            <a @click="this.scrollToHistory" href="#"><span>About</span></a>
        </div>
        <div class="menu__item">
            <a @click="this.scrollToPortfolio" href="#"><span>Work</span></a>
        </div>
        <div class="menu__item">
            <a @click="this.scrollToContact" href="#"><span>Contact</span></a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Menu',
        data() {
            return {
                scrollHistory: false,
                scrollContact: false,
                scrollPortfolio: false
            }
        },
        methods: {
            scrollToHistory() {
                this.scrollHistory = true
                this.$emit('historyScroll', this.scrollHistory)
            },
            scrollToContact() {
                this.scrollContact = true
                this.$emit('contactScroll', this.scrollContact)
            },
            scrollToPortfolio() {
                this.scrollPortfolio = true
                this.$emit('portfolioScroll', this.scrollPortfolio)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .menu{
        &__item{
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            padding: 2em 0.75em;
            position: relative;
            @media (min-width: 768px) {
                padding: 2em;
            }
            &--image{
                background-image: url("../assets/freelancepete.png");
                background-size: 100%;
                background-position-y: 50%;
                background-repeat: no-repeat;
            }
            img{
                display: inline-block;
                width: 100%;
            }
        }
        &__border{
            border: 2px solid #fff;
        }
    }
    @keyframes cursorAnimation {
        0%{
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
    }
    a {
        color: white;
        font-size: 1em;
        text-transform: uppercase;
        letter-spacing: 3px;
        text-align: left;
        @media (min-width: 768px) {
            font-size: 1.5em;
        }
        span{
            position: relative;
            &:hover{
                &:after{
                    display: block;
                }
            }
            &:after{
                display: none;
                transition: all 0.1s ease-in-out;
                content: '';
                position: absolute;
                border-bottom: 4px solid #323430;
                top: 5px;
                right: 1px;
                width: 100%;
                height: 34px;
                animation: cursorAnimation 1s infinite;
            }
        }
        &:hover{
            color: white;
            text-decoration: none;
        }
    }
</style>