<template>
    <div class="main w-100 d-flex align-items-end align-items-md-center justify-content-center">
        <div id="greeting" class="greeting"><p>{{ this.greeting.join('') }}</p></div>
    </div>
</template>

<script>
    export default {
        name: 'IntroMain',
        data() {
            return {
                myArray: this.timeOfDay(),
                greeting: [],
                count: 0,
                increment: 0,
                timer: undefined
            }
        },
        mounted () {
            this.callTimer()
        },
        watch: {
            increment: function () {
                if (this.increment < this.myArray.length) {
                    this.callTimer()
                } else if (this.increment === this.myArray.length) {
                    this.increment = 0
                }
            }
        },
        methods: {
            timeOfDay () {
                let d = new Date
                let hr = d.getHours()
                if (hr > 0 && hr < 12) {
                    return [`good morning`, 'guten morgen', 'bonjour']
                } else if (hr >= 12 && hr < 18) {
                    return [`good afternoon`, 'guten tag', 'bonjour']
                } else {
                    return [`good evening`, 'guten abend', 'bonsoir']
                }
            },
            callTimer () {
                if (this.count < this.myArray[this.increment].length) {
                    this.timer = setInterval(this.message, 120)
                }
            },
            sleep (time) {
                return new Promise((resolve) => setTimeout(resolve, time))
            },
            message () {
                let visibility = document.getElementById('greeting').style.visibility
                let word = this.myArray[this.increment]
                let len = word.length
                let split = word.split('')[this.count]
                if (this.count >= len) {
                    this.sleep(3000).then(() => {
                        this.count = 0
                        this.increment++
                        this.timer = 0
                        this.greeting = []
                    })
                    clearInterval(this.timer);
                    return
                }
                this.count++
                this.greeting.push(split)
                visibility = 'hidden'
            },
        }
    }
</script>

<style lang="scss" scoped>
    .main{
        height: 100%;
        text-align: center;
    }
    @keyframes cursorAnimation {
        0%, 100%{
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
    }
    @keyframes opacity {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    div {
        font-size: 1.5em;
        @media (min-width: 768px) {
            font-size: 2em;
        }
        &.greeting{
            /*border-bottom: 4px solid #323430;*/
            /*border-left: 4px solid #323430;*/
            max-height: 36px;
            height: 100%;
            padding-left: 0.5em;
            padding-bottom: 0.5em;
            margin-top: 3em;
            padding-right: 0.7em;
            animation: opacity 0.75s ease-in-out 1;
            text-rendering: optimizeLegibility;
            @media (min-width: 768px) {
                padding-left: 0.4em;
                padding-right: 0.565em;
            }
            p{
                position: relative;
                top: -36px;
                @media (min-width: 768px) {
                    top: -98px;
                }
                &:after{
                    display: block;
                    content: '';
                    position: absolute;
                    border-bottom: 4px solid #323430;
                    top: 0;
                    right: -22px;
                    width: 20px;
                    height: 38px;
                    animation: cursorAnimation 1.25s infinite;
                    @media (min-width: 768px) {
                        top: -7px;
                        right: -46px;
                        width: 40px;
                        height: 75px;
                    }
                }
            }
        }
    }
</style>