<template>
    <div class="d-flex flex-column w-100">
        <div class="d-flex flex-column col-12">
            <div class="d-flex mt-3 flex-column">
                <p class="timeline__title"><strong>Portfolio</strong></p>
                <p class="timeline__text">Developing Frontend builds on websites such as shown below.</p>
            </div>
        </div>
        <div class="row">
            <div class="container">
                <div v-if="item.img" class="portfolio__piece d-flex flex-column col-12 col-md-6 col-lg-4" :class="item.class" v-for="item in history.portfolio" :key="history.item">
                    <img :src="require('../assets/' + item.img)" :alt="item.title">
                    <a class="mt-3 mb-3 font-weight-bold" target="_blank" :href="item.link">{{item.title}}</a>
                </div>
            </div>
        </div>
        <p class="further__text mt-5 text-center text-md-right pr-3">*further work not shown due to NDA</p>
    </div>
</template>

<script>
    export default {
        name: "Portfolio",
        data () {
            return {
                history: {
                    'portfolio': [
                    {
                        'img': 'CoachUSA.png',
                        'title': 'CoachUSA',
                        'class': 'active',
                        'link': 'http://www.coachusa.com'
                    }, 
                    {
                        'img': 'Itchpet.png',
                        'title': 'Itchpet',
                        'class': 'active',
                        'link': 'https://www.itchpet.com/'
                    },
                    {
                        'img': 'rentsense.png',
                        'title': 'RentSense',
                        'class': '',
                        'link': 'https://www.mobysoft.com/rentsense/'
                    },{
                        'img': 'realbuzz.png',
                        'title': 'The Realbuzz Group',
                        'class': '',
                        'link': 'http://www.realbuzz.com'
                    }, {
                        'img': 'rcpi.png',
                        'title': 'Royal College of Physicians of Ireland',
                        'class': '',
                        'link': 'https://www.rcpi.ie/'
                    }, {
                        'img': 'eb.png',
                        'title': 'Ellis Brigham',
                        'class': '',
                        'link': 'https://www.ellis-brigham.com/'
                    }, {
                        'img': 'outsiders.png',
                        'title': 'The Outsiders Store',
                        'class': '',
                        'link': 'https://www.outsidersstore.com/'
                    }, {
                        'img': 'snowboardasylum.png',
                        'title': 'The Snowboard Asylum',
                        'class': '',
                        'link': 'https://www.snowboard-asylum.com/'
                    }, {
                        'img': 'toughmudder.png',
                        'title': 'Tough Mudder',
                        'class': '',
                        'link': 'https://toughmudder.co.uk/'
                    }]
                }
            }
        },
        mounted () {
            this.activeState()
        },
        methods: {
            activeState () {
                let array = this.history.portfolio
                let i = 0
                let timer = setInterval(myFunction, 1500);

                function myFunction() {
                    if(i === array.length - 1) {
                        array[i].class = ''

                        i = 0
                        return
                    }
                    array[i].class = ''
                    i++
                    array[i].class = 'active'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .further__text {
        font-style: italic;
        font-size: 0.75em;
        @media (min-width: 768px) {
            font-size: 1em;
        }
    }
    .timeline__title{
        font-size: 1.5em;
    }
    .portfolio{
        &__piece{
            list-style-type: none;
            margin: 0;
            padding: 0;
            width: 100%;
            float: left;
            &.active{
                img {
                    -webkit-filter: grayscale(0%);
                    filter: grayscale(0%);
                    transition: filter 0.2s ease-in-out;
                }
            }
            a{
                color: #fff;
                &:hover{
                    text-decoration: none;
                }
            }
            img{
                padding: 1em;
                width: 100%;
                max-width: 100%;
                display: flex;
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                transition: filter 0.2s ease-in-out;
                max-height: 260px;
                &:hover{
                    -webkit-filter: grayscale(0%);
                    filter: grayscale(0%);
                    transition: filter 0.2s ease-in-out;
                }
            }
        }
    }
</style>