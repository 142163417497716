<template>
    <div class="d-flex flex-column justify-content-center h-100">
        <p class="title mb-1"><strong>Contact Pete</strong></p>
        <p class="mb-4"><strong>Please contact me with any enquiries.</strong></p>
        <p class="mb-2"><font-awesome-icon icon="envelope"/><span class="ml-2">peter.david.mchugh@gmail.com</span></p>
        <p><font-awesome-icon icon="phone-alt"/><span class="ml-2">(+44) 777 276 5678</span></p>
        <div v-if="submit" class="mt-5" id="messageSent">
            <p class="message-sent">Message received!</p>
        </div>
        <div v-else id="messageForm">
            <p class="mt-3 mb-4"><strong>OR</strong></p>
            <div class="d-flex justify-content-center">
                <form class="form d-flex flex-column align-items-end pt-2" v-on:submit="this.formSubmitted" action="../contact.php#messageSent" method="post">
                    <input type="text" required placeholder="Name" id="name" name="name" autocomplete="false">
                    <input class="mt-3" type="email" required id="email" placeholder="Email" name="email" autocomplete="false">
                    <textarea class="mt-3 mb-3" id="message" rows="4" name="message" placeholder="I'd like to buy you a beer..."/>
                    <button  name="submit" type="submit"><span class="mr-2">Send</span><font-awesome-icon icon="arrow-circle-right"/></button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'contactme',
        data () {
            return {
                submit: false
            }
        },
        methods: {
            formSubmitted () {
                this.submit = true
                return false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .message-sent{
        font-size: 1.5em;
    }
    .title{
        font-size: 1.5em;
        span{
            font-size: 0.75em;
        }
    }
    form{
        input, textarea{
            min-width: 324px;
            padding: 0.5em;
            border: none;
            max-height: 192px;
        }
        textarea {
            min-height: 92px;
        }
    }
    button{
        background: none;
        border: 2px solid #fff;
        border-radius: 8px;
        color: #fff;
        font-weight: 600;
        padding: 0.5em 1em;
        min-width: 96px;
    }
</style>